import React, { useState } from 'react';

import { clientAxios } from 'utility/api/operator/axiosOperator';
import { API } from '../../resources/api-constants';
import { useParams } from 'react-router-dom';

const useOperatorGameListLogic = () => {

    //GET GAMES
    const [post, setPost] = React.useState({ data: Array(2) });
    const [error, setError] = useState(null);
    const [total, setTotal] = React.useState(0);
    const [gameCategory, setGameCategory] = React.useState('');

    const [loadingStatus, setLoadingStatus] = React.useState(true); //check loading status

    // function to fetch all table data
    const [pageParams, setPageParams] = useState(useParams());
    const [categoryId, setCategoryId] = useState(pageParams.id);
    const [pageNumber, setPageNumber] = useState(pageParams.page);

    //remember current category
    localStorage.setItem('category-id', String(pageParams.id));

    function getPostData(categoryId: any, categoryName: any, pageNumber: any, searchParam = '', gameStatus ='') {
        //let queryType = "crypto";

        //if (categoryId === '1') {
        //    queryType = 'esports';
        //} else if (categoryId === '3') {
        //    queryType = 'sports';
        //}

        clientAxios.get(
            API.OPERATOR_GAMES_LINK + '/' + categoryName + '/' + categoryId, {
                params: {
                    offset: API.NUM_PER_PAGE * (pageNumber - 1),
                    limit: API.NUM_PER_PAGE,
                    search: searchParam,
                    status: gameStatus
                },
        }
        ).then((response) => {
            setPost(response.data);
            setTotal(response.data.count);
            setGameCategory(response.data.data[0].categories[0].name);

            setLoadingStatus(false);
            setError(null);
        }).catch((setError) => {
            setLoadingStatus(false);
        });

        
    }
    function getCategories() {
        clientAxios.get(
            API.OPERATOR_CATEGORIES_LINK, {
        }
        ).then((response) => {
            response?.data?.data.forEach(function (category:any , count:any) {
                if (categoryId == category.id) {
                    setGameCategory(category.name);
                    getPostData(categoryId, category.name, pageNumber, '');
                }
            });
        }).catch((error) => {
        });
    }

    //fetch on initial load
    React.useEffect(() => {
        getCategories();
    }, []);
    //END GET GAMES

    const [show, setShow] = useState(false);
    const [showToast, setShowToast] = useState(false);

    const transaction = 'add-game';
    const handleClose = () => setShow(false);
    const handleCloseToast = () => setShowToast(false);

    const [showToastType, setShowToastType] = useState('success');

    const handleSubmit = function (e: any, result: any) {

        e.preventDefault();

        //api call
        if (result) {
            setShowToastType('success');
        } else {
            setShowToastType('fail');
        }

        setShow(false);
        setShowToast(true);
    }

    const handleShow = (type: any) => setShow(true);

    //CONFIRM DEACTIVATE
    const [showConfirmation, setConfirmation] = useState(false);
    const [fullName, setFullName] = useState('');
    const [id, setId] = useState('');
    const handleCloseConfirmation = () => setConfirmation(false);

    const [confirmationType, setConfirmationType] = useState('');

    const handleConfirmation = function (type: any, fullName: any, propId: any) {
        setConfirmation(true);

        setConfirmationType(type);
        setFullName(fullName);
        setId(propId);

    }
    //END CONFIRM DEACTIVATE

    // ACTIVATE OR DEACTIVATE
    const [deactivateResult, setDeactivateResult] = React.useState({ data: Array(2) });
    const [errorDeactivate, setErrorDeactivate] = useState(null);

    const handleConfirmationSubmit = (link: any) => (e: any) => {

        setConfirmation(false);
        e.preventDefault();

        //api call
        clientAxios.put(
            link,
            {
                id: id
            }
        ).then((response) => {
            setDeactivateResult(response);
            setShowToastType('success');
            getPostData(categoryId, gameCategory, pageNumber);
        }).catch((error) => {
            setShowToastType('fail');
            setErrorDeactivate(error);
        });
        
        setShowToast(true); // show toast

    }
    // END ACTIVATE OR DEACTIVATE

    //SEARCH
    const [showCloseButton, setShowCloseButton] = useState('invisible');
    const [searchParam, setSearchParam] = useState('');

    // Input Field handler
    const handleSearchInput = (e: any) => {
        setSearchParam(e.target.value);
    };


    const handleSearch = (e: any) => {
        e.preventDefault();

        setShowCloseButton('visible');
        getPostData(categoryId, pageNumber, searchParam);
    }

    const resetSearch = (e: any) => {
        setSearchParam('');

        e.preventDefault();

        setShowCloseButton('invisible');

        getPostData(categoryId, pageNumber, '');
    }
    //END SEARCH

    //FILTER
    const [dropdownText, setDropdownText] = useState('All');
    const [gameType, setGameType] = useState('');
    const handleDropdown = (e: any) => {

        e.preventDefault();

        setDropdownText(e.target.text);
        
        let gameStatus = '';
        let gameText = (e.target.text);

        if ('Active' == gameText) {
            gameStatus = '1';
        } else if ('Inactive' == gameText) {
            gameStatus = '0';
        } else {
            gameStatus = '';
        }

        getPostData(categoryId, pageNumber, searchParam, gameStatus);
    }

    //END FILTER

    return {
        showToast, handleCloseToast, showToastType,
        show, handleClose, handleSubmit, transaction,
        showConfirmation, handleConfirmationSubmit, handleCloseConfirmation, confirmationType, fullName,
        handleShow, handleConfirmation,
        categoryId, gameCategory,
        post, total, pageNumber,
        loadingStatus,
        handleDropdown, dropdownText,
        handleSearch, resetSearch, handleSearchInput, searchParam, showCloseButton
    };
}

export default useOperatorGameListLogic;