import { useState } from 'react'
import { useEffect } from 'react'

import { ROUTES } from '../../../resources/routes-constants'
import { API } from '../../../resources/api-constants';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import Offcanvas from 'react-bootstrap/Offcanvas';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

//font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faHouse, faCartShopping, faUpload, faMoneyBill, faCoins, faPenToSquare, faList, faRightFromBracket, faPeopleCarryBox, faHandHoldingHeart, faBell
} from '@fortawesome/free-solid-svg-icons'

import BottomNavbarAgent from './BottomNavbarAgent';

import { clientAxios } from 'utility/api/agent/axiosAgent';
import { formatNumberToCurrencyRoundUp } from 'utility/functions';
import AgentCustomModal from 'components/agents/modals/AgentCustomModal';


function TopNavbarAgent() {
    const [totalCredits, setTotalCredits] = useState('');
    const [show, setShow] = useState(false);

    const [totalPlayerRequest, setTotalPlayerRequest] = useState('');
    const [newNotif, setNewNotif] = useState(false);

    function init() {
        const userSession = localStorage.getItem('user-session');
        if (userSession !== API.AGENT_CODE) {
            window.location.href = ROUTES.AGENT_LOGIN_ROUTE;
        }

        const loggedInUser = localStorage.getItem('access-token');

        if (!loggedInUser) { // not logged in, redirect
            localStorage.clear();
            window.location.href = ROUTES.AGENT_LOGIN_ROUTE;
        }

        //GET TOTAL CREDITS
        clientAxios.get(
            API.AGENT_ACCOUNT_LINK,
            {}
        ).then((response) => {
            //check if mobile number exists
            if ('' === response.data.data.mobileNumber) {
                window.location.replace(ROUTES.AGENT_MOBILE_UPDATE_ROUTE);
            } else {
                //redirect to validate mobile if mobile is not yet confirmed
                if (response.data.data.verified === false) {
                    window.location.replace(ROUTES.AGENT_VALIDATE_ROUTE);
                }
            }

            localStorage.setItem('total-credits', response.data.data.wallet.balance);
            localStorage.setItem('email-address', response.data.data.email);
            localStorage.setItem('name', response.data.data.name);
            localStorage.setItem('total-commissions', response.data.data.agent.commission_wallet.amount);
            localStorage.setItem('user-role', response.data.data.role.title);

            setTotalCredits(response.data.data.wallet.balance);

            if (response.data.data.verified === false) {
                localStorage.setItem('user-verified', '0');
            } else {
                localStorage.setItem('user-verified', '1');
            }

        }).catch((error) => {
        });

        //END GET TOTAL CREDITS
    }

    //get new player requests
    function getPlayerRequests() {
        clientAxios.get(
            API.AGENT_DOWNLINES_CREDIT_REQUEST_LINK, {
        }
        ).then((response) => {
            setTotalPlayerRequest(response.data.count);

            if (response.data.count > 0) {
                setNewNotif(true);
            } else {
                setNewNotif(false);
            }

        }).catch((error) => {
            if ('INVALID_TOKEN' === error?.response?.data?.result) {
                setShow(true);
                localStorage.clear();
                localStorage.setItem('expires', 'true');
            }
        });
    }

    //end get new player requests

    const getNotifications = () => {
        Promise.all([getPlayerRequests()])
            .then(results => {
                const notificationTimer = setTimeout(() => { getNotifications(); }, 10000);

                if (localStorage.getItem('expires') === 'true') {
                    clearTimeout(notificationTimer);
                }
            }
            );
    };

    useEffect(() => {
        init();

        //check for new requests
        localStorage.setItem('expires', 'false');
        getNotifications();
    }, []);

    return (
        <div>
            {/* DISPLAY IF SESSION EXPIRES */}
            <AgentCustomModal show={show} transaction='session' />
            {/* END DISPLAY IF SESSION EXPIRES */}

            {[false].map((expand) => (
                <Navbar key={null} expand={expand} className="mb-3 bg-primary">
                    <Container fluid className="topNav-container">
                        <Container fluid>
                            <Row>
                                <Col md={1} className='d-none d-md-block '>
                                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} className='' />
                                </Col>
                                <Col md={7} className='d-none d-md-block '>
                                    <Navbar.Brand href={ROUTES.AGENT_ACCOUNT_ROUTE} className='m-0 p-0'>
                                        <Row>
                                            <Col xs={4}>
                                                {(localStorage.getItem('name') === 'null' || null === localStorage.getItem('name')) ?
                                                    '' :
                                                    localStorage.getItem('name')
                                                }
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={4} className="mt-0 pt-0">
                                                <small className='text-warning mt-0 pt-0'>
                                                    {localStorage.getItem('email-address')}
                                                </small>
                                            </Col>
                                        </Row>
                                    </Navbar.Brand>
                                </Col>
                                <Col xs={12} md={4}>
                                    <Row className="text-end">
                                        <Col xs={11} md={10}>
                                            <a href={ROUTES.AGENT_CREDITS_BUY_ROUTE} className='text-decoration-none'><FontAwesomeIcon className='me-1 text-warning mt-3' icon={faCoins} /> CREDITS: <span id='totalCredits' className="text-warning">{formatNumberToCurrencyRoundUp(localStorage.getItem('total-credits'))}</span></a>
                                        </Col>
                                        <Col xs={1} md={2}>

                                        {
                                            newNotif ?
                                                <a href={ROUTES.AGENT_DOWNLINE_REQUESTS_ROUTE + '/1'} className='text-end'>
                                                    <FontAwesomeIcon icon={faBell} className='h5 text-danger blinkText ms-4 mt-3' />
                                                </a>
                                                :
                                                    <FontAwesomeIcon icon={faBell} className='h5 text-secondary ms-4 mt-3' />
                                         }
                                        </Col>
                                    </Row>
                                </Col>
                                {/*<Col xs={1} md={1} className='mt-4'>*/}
                                {/*    <Row>*/}
                                {/*        {*/}
                                {/*            newNotif ?*/}
                                {/*                <a href={ROUTES.AGENT_DOWNLINE_REQUESTS_ROUTE + '/1'} className='text-end'>*/}
                                {/*                    <FontAwesomeIcon icon={faBell} className='h5 text-danger blinkText'/>*/}
                                {/*                </a>*/}
                                {/*                :*/}
                                {/*                <FontAwesomeIcon icon={faBell} className='h5 text-secondary' />*/}
                                {/*        }*/}
                                {/*    </Row>*/}
                                {/*</Col>*/}
                            </Row>
                        </Container>
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-${expand}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                            placement="start"
                            style={{ maxWidth: '300px' }}
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`} className='header-title p-0'>
                                    {/* <img src={window.location.origin + '/logo.png'} className='w-25 align-center me-2'
                                        style={{ background: '#FFF' }}
                                    /> GGWPlay Agent */}
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Container className='text-center'>
                                    <Row>
                                        <Col className='text-start'>
                                            <span id="fullName" className='text-white'>
                                                {(localStorage.getItem('name') == 'null' || null == localStorage.getItem('name')) ?
                                                    '' :
                                                    localStorage.getItem('name')
                                                }
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={4} className="mt-0 pt-0">
                                            <small className='text-warning mt-0 pt-0'>
                                                {localStorage.getItem('email-address')}
                                            </small>
                                        </Col>
                                    </Row>
                                    {/* <div className='d-flex flex-row'>
                                        <div className='text-center'>
                                            <img src='../logo.png' className='w-50' />
                                        </div>
                                        <div className='text-start'
                                            style={{ width: '350px', overflow: 'scroll' }}
                                        >
                                            {(localStorage.getItem('name') == 'null' || null == localStorage.getItem('name')) ?
                                                '' :
                                                localStorage.getItem('name')
                                            }
                                            <br />
                                            <small className='text-warning mt-0 pt-0'>
                                                {localStorage.getItem('email-address')}
                                            </small>
                                        </div>
                                    </div> */}
                                </Container>
                                <Nav className="justify-content-end flex-grow-1 pe-3 mt-5">
                                    <Nav.Link href={ROUTES.AGENT_HOME_ROUTE} className='p-1'>
                                        <FontAwesomeIcon icon={faHouse} className='me-3' />Dashboard
                                    </Nav.Link>
                                    <hr />
                                    {/* <Nav.Link href={ROUTES.AGENT_CREDITS_SEND_ROUTE} className='p-1'><FontAwesomeIcon icon={faUpload} className='me-3' />Send Credits</Nav.Link> */}
                                    <Navbar.Text className=''>WALLET</Navbar.Text>
                                   
                                    {localStorage.getItem('user-role') === 'Master Agent' ?
                                        <Nav.Link href={ROUTES.AGENT_CREDITS_BUY_ROUTE} className='p-1'><FontAwesomeIcon icon={faCartShopping} className='me-3' />Buy</Nav.Link>
                                        : <></>
                                    }
                                    <Nav.Link href={ROUTES.AGENT_WITHDRAW_ROUTE} className='p-1'><FontAwesomeIcon icon={faUpload} className='me-3' />Withdraw</Nav.Link>
                                    <Nav.Link href={ROUTES.AGENT_TRANSACTIONS_ROUTE + '/1'} className='p-1'><FontAwesomeIcon icon={faList} className='me-3' />Transactions</Nav.Link>
                                    <hr />
                                    <Navbar.Text className=''>COMMISSION</Navbar.Text>
                                    <Nav.Link href={ROUTES.AGENT_COMMISSION_CONVERT_ROUTE} className='p-1'><FontAwesomeIcon icon={faMoneyBill} className='me-3' />Convert</Nav.Link>
                                    <Nav.Link href={ROUTES.AGENT_COMMISSION_TRANSACTIONS_ROUTE + '/1'} className='p-1'><FontAwesomeIcon icon={faList} className='me-3' />Transactions</Nav.Link>
                                    {/*<Nav.Link href={ROUTES.AGENT_STATS_ROUTE + '/1'} className='p-1'><FontAwesomeIcon icon={faList} className='me-3' />Stats</Nav.Link>*/}
                                    <hr />
                                    <Navbar.Text className=''>DOWNLINES</Navbar.Text>
                                    <Nav.Link href={ROUTES.AGENT_DOWNLINES_ROUTE + '/1'} className='p-1'><FontAwesomeIcon icon={faPeopleCarryBox} className='me-3' />List</Nav.Link>
                                    {localStorage.getItem('user-role') === 'Master Agent' ?
                                        <>
                                            <Nav.Link href={ROUTES.AGENT_DOWNLINE_REQUESTS_ROUTE + '/1'} className='p-1'><FontAwesomeIcon icon={faHandHoldingHeart} className='me-3' />
                                                Requests
                                                {
                                                    (Number(totalPlayerRequest) > 0) &&
                                                        <span className="ms-2 badge bg-primary">{totalPlayerRequest}</span>
                                                }
                                            </Nav.Link>
                                            <Nav.Link href={ROUTES.AGENT_DOWNLINE_TRANSACTIONS_ROUTE + '/1'} className='p-1'><FontAwesomeIcon icon={faList} className='me-3' />History</Nav.Link>
                                        </>
                                        : <></>
                                    }
                                    <hr />
                                    <Navbar.Text className=''>ACCOUNT SETTINGS</Navbar.Text>
                                    <Nav.Link href={ROUTES.AGENT_ACCOUNT_ROUTE} className='p-1'><FontAwesomeIcon icon={faPenToSquare} className='me-3' />Edit Account</Nav.Link>
                                    <Nav.Link href={ROUTES.AGENT_LOGOUT_ROUTE} className='p-1'><FontAwesomeIcon icon={faRightFromBracket} className='me-3' />Sign Out</Nav.Link>
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
            ))}

            {/* <Container fluid className='fixed-bottom mb-3 d-none d-md-block'>
                <Container >
                    <Row>
                        <Col md={{ span:11 }}>&nbsp;</Col>
                        <Col md={{ span: 1 }}>
                            <a href={ROUTES.HOME_ROUTE} className='text-primary'>
                                <Image fluid src='/images/games-animated.gif' className='border border-dark' roundedCircle width={50} />
                            </a>

                        </Col>
                    </Row>
                </Container>
            </Container> */}

            <BottomNavbarAgent newNotif={newNotif} totalPlayerRequest={totalPlayerRequest} />
        </div>

    );
}

export default TopNavbarAgent;