import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

import { clientAxios } from '../../../utility/api/admin/axiosCMS';
import { API } from '../../../resources/api-constants';
import { ROUTES } from '../../../resources/routes-constants';

// function CustomModal() {
const AdminCustomModal = (props: any) => {
    const handleClose = props.close;
    const handleSubmit = props.submit;
    const transaction = props.transaction;
    const show = props.show;

    const [buttonEnable, setButtonEnable] = useState(true);

    //ADD
    const initialFormData = Object.freeze({
        username: "",
        email: "",
        mobilenumber: "",
        name: "",
        gcash: ""
    });

    const [formData, updateFormData] = React.useState(initialFormData);

    const handleChange = (e: any) => {
        updateFormData({
            ...formData,

            // Trimming any whitespace
            [e.target.name]: e.target.value.trim()
        });
    };

    const [result, setResult] = useState(false);

    const handleSubmitAPI = function (e: any) {
        e.preventDefault();

        setButtonEnable(false);

        //api call
        if ('add-operator' === transaction) {
            clientAxios.post(
                API.ADMIN_OPERATORS_CREATE_LINK,
                {
                    username: formData.username,
                    name: formData.name,
                    email: formData.email
                }
            ).then((response: any) => {
                handleSubmit(e, true);
            }).catch((error: any) => {
                handleSubmit(e, false);
            }).finally(() => {
                setButtonEnable(true);
            });
        } else if ('add-agent' === transaction) {
            clientAxios.post(
                API.ADMIN_AGENTS_CREATE_LINK,
                {
                    username: formData.username,
                    name: formData.name,
                    mobileNumber: formData.mobilenumber,
                    email: formData.email,
                    percentage: "50" // fixed 50%
                }
            ).then((response: any) => {
                handleSubmit(e, true);
            }).catch((error: any) => {
                handleSubmit(e, false);
            }).finally(() => {
                setButtonEnable(true);
            });
        } else if ('add-gcash' == transaction) {
            clientAxios.post(
                API.ADMIN_GCASH_CREATE_LINK,
                {
                    name: formData.name,
                    mobileNo: formData.gcash
                }
            ).then((response: any) => {
                handleSubmit(e, true);
            }).catch((error: any) => {
                handleSubmit(e, false);
            }).finally(() => {
                setButtonEnable(true);
            });
        } else {
            return;
        }
    }
    //END ADD

    if ('add-operator' === transaction) {
        return (
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>New Operator</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmitAPI}>
                    <Modal.Body className="">
                    <Form.Group className="mb-3">
                            <Form.Label><h6>Username</h6></Form.Label>
                            <Form.Control type="text" name='username' placeholder="username" required onChange={handleChange} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label><h6>Email</h6></Form.Label>
                            <Form.Control type="email" name='email' placeholder="email@email.com" required onChange={handleChange} />
                            <Form.Text className="text-muted">This will be the user login.</Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label><h6>Full Name</h6></Form.Label>
                            <Form.Control type="text" placeholder="full name" name='name' required onChange={handleChange} />
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        {
                            (buttonEnable) ?
                            <Button variant="primary" type='submit'>
                                Submit
                            </Button>
                            :
                            <Button variant="primary" type='button' disabled>
                                Submit
                            </Button>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>

        );
    } else if ('add-agent' === transaction) {
        return (
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>New Master Agent</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmitAPI}>
                    <Modal.Body className="">
                        <Form.Group className="mb-3">
                            <Form.Label><h6>Username</h6></Form.Label>
                            <Form.Control type="text" name='username' placeholder="username" required onChange={handleChange} />
                            <Form.Text className="text-muted">This will be the user login.</Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label><h6>Mobile Number</h6></Form.Label>
                            <Form.Control type="tel" name='mobilenumber' pattern="^(63\d{10})$" maxLength={12} placeholder="63xxxxxxx" required onChange={handleChange} />
                            <Form.Text className="text-muted">Accepted format: 639xxxxxxxxx</Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label><h6>Email</h6></Form.Label>
                            <Form.Control type="email" name='email' placeholder="email@email.com" required onChange={handleChange} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label><h6>Full Name</h6></Form.Label>
                            <Form.Control type="text" placeholder="full name" name='name' required onChange={handleChange} />
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        {
                            (buttonEnable) ?
                            <Button variant="primary" type='submit'>
                                Submit
                            </Button>
                            :
                            <Button variant="primary" type='button' disabled>
                                Submit
                            </Button>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>

        );
    } else if ('add-category' === transaction) {
        return (
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>New Category</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmitAPI}>
                    <Modal.Body className="">
                        <Form.Group className="mb-3">
                            <Form.Label><h6>Email</h6></Form.Label>
                            <Form.Control type="text" name='name' placeholder="Category Name" required onChange={handleChange} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="example Form.ControlInput1">
                            <Form.Label><h6>Image</h6></Form.Label>
                            <Form.Control type="file" placeholder="Choose File" name='image' />
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type='submit'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    } else if ('add-gcash' === transaction) {
        return (
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>New GCash</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmitAPI}>
                    <Modal.Body className="">
                        <Form.Group className="mb-3">
                            <Form.Label><h6>Name</h6></Form.Label>
                            <Form.Control type="text" placeholder="full name" name='name' required onChange={handleChange} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label><h6>GCash Number</h6></Form.Label>
                            <Form.Control type="number" name='gcash' placeholder="099xxxx" required onChange={handleChange} />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        {
                            (buttonEnable) ?
                            <Button variant="primary" type='submit'>
                                Submit
                            </Button>
                            :
                            <Button variant="primary" type='button' disabled>
                                Submit
                            </Button>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    } else if ('session' === transaction) {

        return (

            <Modal show={props.show} className='mt-5 modal-dialog-centered'>
                <Modal.Header className=''>
                    <Modal.Title>Session Expired</Modal.Title>
                </Modal.Header>
                <Modal.Body className='pb-5 mb-5 pt-5 text-center'>
                    <p>Your session has expired. </p>
                    <p><a href={ROUTES.ADMIN_LOGIN_ROUTE}>Click here to log in.</a></p>
                    {/* <small>NOTE: Click "Remember Me" if you want a longer sesison.</small> */}
                </Modal.Body>
            </Modal>
        );
    } else {
        return (
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>New Agent</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmitAPI}>
                    <Modal.Body className="">
                        <Form.Group className="mb-3" controlId="exampl e Form.ControlInput1">
                            <Form.Label><h6>Email</h6></Form.Label>
                            <Form.Control type="email" name='email' placeholder="email@email.com" required onChange={handleChange} />
                            <Form.Text className="text-muted">This will be the user login.</Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="example Form.ControlInput1">
                            <Form.Label><h6>Full Name</h6></Form.Label>
                            <Form.Control type="text" placeholder="full name" name='name' required onChange={handleChange} />
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type='submit'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    }

}

export default AdminCustomModal;